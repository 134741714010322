import * as validators from '@vuelidate/validators'
import i18n from "@/i18n"
const { createI18nMessage } = validators

// Create i18n message instance
const withI18nMessage = createI18nMessage({ t: i18n.global.t.bind(i18n) })

export const required = withI18nMessage(validators.required)

// validators that expect a parameter should have `{ withArguments: true }` passed as a second parameter, to annotate they should be wrapped
export const minLength = withI18nMessage(validators.minLength, { withArguments: true })
export const maxLength = withI18nMessage(validators.maxLength, { withArguments: true })
export const minValue = withI18nMessage(validators.minValue, { withArguments: true })
export const maxValue = withI18nMessage(validators.maxValue, { withArguments: true })
export const email = withI18nMessage(validators.email)
export const integer = withI18nMessage(validators.integer)

// Function can be imported into Vue components and returns the first error message from Vuelidate if any
export const getErrorMessage = function($model) {
    return (this.v$[$model].$error) ? this.v$[$model].$errors[0].$message : null;
}


