<template>
    <div class="eq-modal">
        <div class="eq-modal__header">
            <div class="container">
                <div class="eq-modal__header-inner">
                    <img :src="vc4aLogoPath" class="eq-modal__header-logo"/>
                    <button
                        class="vc4a-btn vc4a-btn--icon"
                        @click="$emit('close:eq-modal');"
                    >
                        <XMarkIcon class="icon"/>
                    </button>
                </div>
            
            </div>
        </div>
        <div class="eq-modal__body">
            <div class="container container--sm">
                <slot></slot>
            </div>
        </div>
    </div>
</template>

<script>
import {VC4A_VARS} from '@/shared/utlis';
import { XMarkIcon } from '@heroicons/vue/24/solid';

export default {
    name: "eq-modal",
    emits: ['close:eq-modal'],
    components: {
        XMarkIcon
    },
    data() {
        return {
            vc4aLogoPath: `${VC4A_VARS.VC4A_SITE_ASSETS_PATH}images/svg/vc4a.svg`,
        }
    }
}
</script>
