<template>
    <div class="mentor-filter__wrap">
        <template v-if="errorMessage">
            {{ errorMessage }}
        </template>
        <ul class="find-mentor-hero__filter-list grid list-unstyled cols-4@lg cols-3@md">
            <!-- Name -->
            <li>
                <div class="label">
                    {{ $t('mentor_filter.labels.name') }}
                </div>
                <input
                    type="text"
                    class="form-field"
                    :placeholder="$t('mentor_filter.placeholders.name')"
                    @input="debounceFunction('textFiltersChanged', 's', $event)"
                    :value="filterValues['s']"
                >
            </li>
            <!-- Type -->
            <li>
                <div class="label">
                    {{ $t('mentor_filter.labels.venture_type') }}
                </div>
                <!--Type taxonomy isn't available at the moment-->
                <vc4a-select
                    :placeholder="$t('mentor_filter.labels.venture_type')"
                    :options="mentor_type"
                    :selected="filterValues.mentor_type"
                    @onInput="termsFiltersChanged('mentor_type', $event)"
                    :summarised="true"
                    track-by="item_id"
                    :label="getTermLabel()"
                />
            </li>
            <!-- Venture Stage -->
            <li>
                <div class="label">
                    {{ $t('mentor_filter.labels.stage') }}
                </div>
                <vc4a-select
                    :placeholder="$t('venture_stage')"
                    :options="venture_stage"
                    :selected="filterValues.venture_stage"
                    @onInput="termsFiltersChanged('venture_stage', $event)"
                    :summarised="true"
                    track-by="item_id"
                    :label="getTermLabel()"
                />
            </li>
            <!-- Language -->
            <li>
                <div class="label">
                    {{ $t('mentor_filter.labels.language') }}
                </div>
                <vc4a-select
                    :placeholder="$t('mentor_filter.labels.language')"
                    :options="language_tag"
                    :selected="filterValues.language_tag"
                    @onInput="termsFiltersChanged('language_tag', $event)"
                    :summarised="true"
                    track-by="item_id"
                    :label="getTermLabel()"
                />
            </li>
            <!-- Sector -->
            <li>
                <div class="label">
                    {{ $t('mentor_filter.labels.sector') }}
                </div>
                <vc4a-select
                    :placeholder="$t('sector')"
                    :options="sectors"
                    :selected="filterValues.sectors"
                    @onInput="termsFiltersChanged('sectors', $event)"
                    group-values="childItems"
                    group-label="group"
                    :group-select="true"
                    :summarised="true"
                    track-by="slug"
                    :label="getTermLabel()"
                />
            </li>
            <!-- Country -->
            <li>
                <div class="label">
                    {{ $t('mentor_filter.labels.country') }}
                </div>
                <vc4a-select
                    :placeholder="$t('mentor_filter.labels.country')"
                    :options="focus_locations"
                    :selected="filterValues.focus_locations"
                    group-label="group"
                    group-values="childItems"
                    :group-select="true"
                    @onInput="termsFiltersChanged('focus_locations', $event)"
                    :summarised="true"
                    track-by="slug"
                    :label="getTermLabel()"
                />
            </li>
            <!-- Topics -->
            <li>
                <div class="label">
                    {{ $t('mentor_filter.labels.topics') }}
                </div>
                <vc4a-select
                    :placeholder="$t('mentor_filter.labels.topics')"
                    :options="topic"
                    :selected="filterValues.topic"
                    @onInput="termsFiltersChanged('topic', $event)"
                    :summarised="true"
                    track-by="item_id"
                    :label="getTermLabel()"
                />
            </li>
        </ul>
        <Flash v-if="flash.message" :type="flash.type ? flash.type : 'info'" :message="flash.message"/>
    </div>
</template>

<script>
import Vc4aSelect from "src/views/utility/vc4a-select";
import { GET_MENTORS } from "src/graphql/mentor";
import {
    getTermLabel,
} from "src/shared/terms";
import Flash from "src/views/common/flash";
import i18n from 'src/i18n';
import MentorCard from 'src/views/mentor/card';
import Pagination from 'src/views/layout/pagination';
import Alert from 'src/views/common/alert';
import { createApp } from 'vue';

import _ from "lodash";

export default {
    name: "MentorFilter",
    components: {
        Vc4aSelect,
        Flash
    },
    props: {
        postsPerPage: {
            type: [Number, String],
            default: 18
        },
        currentPage: {
            type: [Number, String],
            default: 1
        },
        partnerId: {
            type: [Number, String],
            default: 0,
            required: false,
        },
        termsJson: {
            type: String,
            default: ''
        },
    },
    data(){
        return {
            filterPanelVisible: false,
            terms: JSON.parse(this.termsJson) || {},
            queryInProgress: false,
            errorMessage: '',
            toggleIsActive: false,
            mentor_type: [],
            sectors: [],
            base_location: [],
            focus_locations: [],
            topic: [],
            venture_stage: [],
            language_tag: [],
            // live field values
            filterValues: {
                mentor_type: [],
                sectors: [],
                topic: [],
                venture_stage: [],
                language_tag: [],
                focus_locations: [],
                s: '', // name-search
            },
            // list of filters (by slug) that will only use strings, never arrays
            textBasedFilterList: [
                's', // name-search
            ],
            hierarchical: [
                'focus_locations'
            ],
            where: {
                'sectors': {
                    'parentId': 0
                }
            },
            sortByTermId: [
                'mentor_support',
                'venture_stage',
                'mentor_type',
            ],
            flash: {
                message: '',
                type: ''
            },
            loadingTerms: 0,
            url: {},
            urlParams: {},
            paged: 1,
            sortBy: {
                label: 'Date DESC',
                value: 'date_desc',
            },
            mentors: [],
        }
    },
    async beforeMount() {
        this.loadingTerms++;
        await this.retrieveTerms();
        this.loadingTerms--;
    },
    mounted() {
        if( this.currentPage > 1 ) {
            this.paged = parseInt( this.currentPage );
        }
        this.url = new URL(window.location);
        this.urlParams = new URLSearchParams(this.url.search);
        let search = window.location.search;
        if( search.replace( '?', '' ) !== '' ) {
            this.filterPanelVisible = true;
        }

        this.sortBy = this.sortOptions[0];
        let _vm = this;
        window.addEventListener("mentor-filter-sort", function(event) {
            _vm.sortByChanged(event.detail);
        }, false);
    },
    methods: {
        setupFilters(taxonomy) {
            let terms = this.getActiveFilterForTaxonomy( taxonomy );

            if (this.textBasedFilterList.includes(taxonomy)) { // if one of the filters that returns/accepts a string
                // set the filter/field value from the url param
                this.filterValues[taxonomy] = this.urlParams.get(taxonomy);

            } else if(!this.textBasedFilterList.includes(taxonomy)) { // if one of the filters that returns/accepts an array
                if( terms ) { // Preselect filter terms from URL
                    // Checks (first element ) if options are grouped/nested
                    let isGrouped = Object.keys(this[taxonomy][0]).includes('childItems');
                    terms.forEach((term) => {
                        if (isGrouped) {
                            this[taxonomy].forEach((groupedItem) => {
                                let itemFound = groupedItem.childItems.find(obj => obj.slug === term);
                                (itemFound) ? this.filterValues[taxonomy].push(itemFound) : false;
                            })
                        } else {
                            let itemFound = this[taxonomy].find(obj => obj.slug === term);
                            (itemFound) ? this.filterValues[taxonomy].push(itemFound) : false;
                        }
                    });
                } else if( !terms ) {
                    // If language_tag is not set, then set it to the default language (if `lang` param is set in the url.
                    if( taxonomy === 'language_tag' && !this.urlParams.get( 'language_tag' ) ) {
                        let langCode = this.urlParams.get( 'lang' )
                            ? this.urlParams.get( 'lang' )
                            : i18n.global.locale;
    
                        for( let lang in this[taxonomy] ) {
                            if( this[taxonomy][lang].slug.startsWith( langCode ) ) {
                                this.filterValues[taxonomy].push({...this[taxonomy][lang]});
    
                                // push language_tag terms into url to make sure filter will take them into consideration.
                                this.urlParams.set(taxonomy, this[taxonomy][lang].slug);
                                window.history.replaceState({}, '', `${this.url.pathname}?${this.urlParams}`);
    
                                return;
                            }
                        }
                    }
                }
            }

        },
        resetPagination() {
            // Reset pagination.
            this.urlParams.delete('paged');
            this.paged = 1;
            this.url.pathname = this.url.pathname.replace(/\/page\/\d+/, '');

            window.history.replaceState({}, '', `${this.url.pathname}?${this.urlParams}`);

            this.filterMentors();
        },
        // Feed me a function and I'll debounce it
        debounceFunction: _.debounce(
            function (functionSlug, filterSlug, event) {
                switch (functionSlug) {
                    case 'textFiltersChanged':
                        this.textFiltersChanged(filterSlug, event)
                    // other functions can be added as new cases
                }
            },
        300),
        textFiltersChanged(filterSlug, event) {
            if(!event.target) {
                return;
            }

            const textFilterStr = event.target.value;

            if( textFilterStr.length >=3 ) {
                this.urlParams.set(filterSlug, textFilterStr);
            } else if( textFilterStr.length < 1 ) {
                this.urlParams.delete(filterSlug);
            }

            this.resetPagination();
        },
        termsFiltersChanged(taxonomy, event) {
            if( this.loadingTerms > 0) {
                return;
            }

            let terms = this.getActiveFilterForTaxonomy(taxonomy)
            terms = [...event.map(e => e.slug)]
            terms = terms.filter( term => term != null );

            // Compare terms from event and terms stored in our variable.
            // If length differs, we need to fetch from GraphQL.
            // This is to prevent unnecessary request on page load.
            let filterTerms = this.filterValues[taxonomy].map(e => e.slug);
            if( filterTerms.length > 0 && terms.length > 0 && filterTerms.length === terms.length ) {
                let triggerFilter = false;
                event.filter(e => {
                    if (filterTerms.indexOf(e.slug) === -1) {
                        triggerFilter = true;
                    }
                });

                if( ! triggerFilter ) {
                    return;
                }
            }

            if( terms.length ) {
                this.urlParams.set(taxonomy, terms)
            } else {
                this.urlParams.delete(taxonomy)
            }

            this.resetPagination();
        },
        getActiveFilterForTaxonomy(taxonomy) {
            this.url = new URL(window.location);
            this.urlParams = new URLSearchParams(this.url.search);
            let terms = this.urlParams.get(taxonomy);

            return terms ? terms.split(',') : null;
        },
        retrieveTerms() {
            Object.keys(this.filterValues).forEach( taxonomy => {
                try {
                    this[taxonomy] = this.terms[taxonomy];
                } catch(e) {
                    this[taxonomy] = [];
                }
                this.setupFilters(taxonomy);
            } );
        },
        displayFlash( flash ) {
            this.flash = flash;
            setTimeout(() => this.flash = { message: '', type: 'info' }, 3000 );
        },
        getComponentHTML(component, props) {
            
            // This is a workaround for Vue.extend(). This method returns the HTML content of a vue component
            // Wrapper element was used here because Vue's mount method requires an element to mount to.
            let wrapperEl = document.createElement('div');
            let app = createApp( component, props )
            app.mount(wrapperEl);
            return wrapperEl.innerHTML;
        },
        filterMentors() {
            this.queryInProgress = true;
            let mentorsWrapper = document.getElementById('mentors-index-wrapper');
            let mentorsPagination = document.getElementById('mentors-index-pagination');
            this.displayMentorsLoadingSnippet(mentorsWrapper, mentorsPagination);
            let where = {
                url: window.location.href,
                paged: this.urlParams.get('paged') ? parseInt( this.urlParams.get('paged') ) : parseInt( this.paged ),
                orderby: this.sortBy.orderby,
                order: this.sortBy.order,
            }

            Object.keys(this.filterValues).forEach( taxonomy => {
                where[taxonomy] = this.urlParams.get(taxonomy);
            } );

            if( parseInt( this.partnerId ) > 0 ) {
                where.partner_id = parseInt( this.partnerId );
            }

            this.$apollo.query( {
                query: GET_MENTORS,
                variables: {
                    first: parseInt( this.postsPerPage ),
                    where: where,
                },
            } ).then( ( { data, loading } ) => {
                if( ! loading ) {
                    let mentorsHtml = '',
                        paginationHtml = '';
                    if (data.mentors.edges.length) {
                        data.mentors.edges.map(mentor => {
                            this.mentors.push(mentor.node);
                            mentorsHtml += this.getComponentHTML(MentorCard, {mentor: mentor.node});
                        });
                        let paginationPropsObj = {
                            foundPosts: data.mentors.pageInfo.resultTotal,
                            maxNumPages: data.mentors.pageInfo.resultMaxPage,
                            currentPage: window.location.current_page,
                        }
                        paginationHtml = this.getComponentHTML(Pagination, paginationPropsObj)

                    } else {
                        let alertPropsObj = {
                            message: 'No profiles found',
                            type: 'warning'
                        }
                        mentorsHtml += this.getComponentHTML(Alert, alertPropsObj)
                    }
                    mentorsWrapper.innerHTML = mentorsHtml;
                    mentorsPagination.innerHTML = paginationHtml;
                    this.queryInProgress = false;
                }
            } ).catch( ( error ) => {
                // Error.
                this.queryInProgress = false;
                this.displayFlash({
                    message: error.message.replace('GraphQL error:', ''),
                    type: 'warning'
                });
            });
        },
        displayMentorsLoadingSnippet(mentorsWrapper, mentorsPagination) {
            let card = `<div class="card" aria-hidden="true">
                    <div class="card__body">
                        <div class="loading-snippet">
                            <div class="loading-snippet__shimmer w-8 h-8 mb-6 flex-shrink-0 flex-grow-0"></div>
                            <p class="loading-snippet__shimmer mb-6" style="width: 50%"></p>
                            <p class="loading-snippet__shimmer mb-3 w-full"></p>
                            <p class="loading-snippet__shimmer m-0 w-full"></p>
                        </div>
                    </div>
                </div>`,
                html = '';
            for( let i=0; i<6; i++ ) {
                html += card;
            }

            mentorsWrapper.innerHTML = html;
            if (mentorsPagination) {
                mentorsPagination.innerHTML = '';
            }
        },
        getTermLabel() {
            return getTermLabel();
        },
        sortByChanged(event) {
            if( event === undefined ) {
                return;
            }
            this.sortBy = {...event};
            this.filterMentors();
        }
    },
    computed: {
        sortOptions() {
            return [
                {
                    label: i18n.global.t( 'sort.date_desc' ),
                    value: 'date_desc',
                    orderby: 'date',
                    order: 'DESC',
                },
                {
                    label: i18n.global.t( 'sort.date_asc' ),
                    value: 'date_asc',
                    orderby: 'date',
                    order: 'ASC',
                },
                {
                    label: i18n.global.t( 'sort.name_asc' ),
                    value: 'name_asc',
                    orderby: 'title',
                    order: 'ASC',
                },
                {
                    label: i18n.global.t( 'sort.name_desc' ),
                    value: 'name_desc',
                    orderby: 'title',
                    order: 'DESC',
                },
            ];
        },
    }
}
</script>
