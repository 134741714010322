<template>
    <Vc4aRadio
        name="iptype"
        :label="$t('initial_estimate.form.ip_type')"
        :radioDataArr="iptypeOptions"
        :modelValue="iptype"
        @update:modelValue="$emit('update:iptype', $event)"
        :error-text="getErrorMessage('iptype')"
        class="mb-6"
    />
    <Vc4aRadio
        name="profitablility"
        :label="$t('initial_estimate.form.profitability', { ventureName: ventureName })"
        :radioDataArr="profitabilityOptions"
        :modelValue="profitability"
        @update:modelValue="$emit('update:profitability', $event)"
        :error-text="getErrorMessage('profitability')"
        class="mb-6"
    />
    <Vc4aTextInput
        :label="$t('initial_estimate.form.revenue_y1')"
        type="number"
        min="0"
        max="1000000000000"
        :modelValue="estimatedRevenueY1"
        @input="$emit('update:estimatedRevenueY1', Number($event.target.value))"
        :error-text="getErrorMessage('estimatedRevenueY1')"
        class="mb-6"
    />
    <Vc4aTextInput
        :label="$t('initial_estimate.form.revenue_y4')"
        type="number"
        min="1"
        max="1000000000000"
        :modelValue="estimatedRevenueY4"
        @input="$emit('update:estimatedRevenueY4', Number($event.target.value))"
        :error-text="getErrorMessage('estimatedRevenueY4')"
    />
</template>

<script>
import Vc4aTextInput from "@stylesComponents/vue/vc4a-text-input.vue"
import Vc4aRadio from "@stylesComponents/vue/vc4a-radio.vue"
import { useVuelidate } from "@vuelidate/core"
import { required, minValue, maxValue, getErrorMessage } from '@stylesComponents/js/i18n-validator'

export default {
    name: 'InitialEstimateStep-3',
    components: {
        Vc4aRadio,
        Vc4aTextInput
    },
    props: {
        iptype: {
            type: String,
        },
        profitability: {
            type: String
        },
        estimatedRevenueY1: {
            type: Number,
            minValue: minValue(0),
            maxValue: maxValue(1000000000000)
        },
        estimatedRevenueY4: {
            type: Number,
            minValue: minValue(0),
            maxValue: maxValue(1000000000000)
        },
        ventureName: {
            type: String,
        },
        iptypeOptions: Array,
        profitabilityOptions: Array,
    },
    setup() {
        return {
            v$: useVuelidate()
        }
    },
    emits: [
        'update:iptype',
        'update:profitability',
        'update:estimatedRevenueY1',
        'update:estimatedRevenueY4',
    ],
    validations() {
        return {
            iptype: { required },
            profitability: { required },
            estimatedRevenueY1: {
                required,
                minValue: minValue(0),
                maxValue: maxValue(1000000000000)
            },
            estimatedRevenueY4: {
                required,
                minValue: minValue(1),
                maxValue: maxValue(1000000000000)
            },
        }
    },
    methods: {
        getErrorMessage
    }
}
</script>
